import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { socialsIcons } from '@profile/shared/components/Socials/icons';
let Socials = class Socials extends Vue {
    get socials() {
        var _a, _b;
        return Object.entries((_b = (_a = this.$configuration) === null || _a === void 0 ? void 0 : _a.byFile('socials')) !== null && _b !== void 0 ? _b : {}).map(([_, value]) => {
            return {
                name: value,
                icon: socialsIcons[value]
            };
        });
    }
    logInWith(social) {
        this.$emit('onSocialSelect', social);
    }
};
Socials = __decorate([
    Component({ name: 'Socials' })
], Socials);
export { Socials };
export default Socials;

// Copyright © 2023 Move Closer
import Google from './Google.vue';
import Twitter from './Twitter.vue';
import LinkedIn from './LinkedIn.vue';
import Facebook from './Facebook.vue';
export const socialsIcons = {
    google: Google,
    facebook: Facebook,
    twitter: Twitter,
    linkedin: LinkedIn
};

// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ModalType } from '@movecloser/front-core';
import { Inject } from '@plugin/inversify';
import { swapModal } from '@support/swap-modal';
/**
 * Base class that every modal **HAS TO** extend.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let AbstractModal = class AbstractModal extends Vue {
    /**
     * Closes the modal.
     */
    close() {
        this.$emit('close');
    }
    /**
     * Determines whether the payload has been injected.
     */
    get hasPayload() {
        return typeof this.payload !== 'undefined' && Object.keys(this.payload).length > 0;
    }
    /**
     * Handles the `@close` event on the `<D24ModalInner>` component.
     */
    onClose() {
        if (this.hasPayload && typeof this.payload.onClose === 'function') {
            this.payload.onClose();
        }
        this.close();
    }
    /**
     * Swaps the modals - closes the currently-opened one and opens the new one.
     *
     * @param newModal - The modal that is to be opened.
     * @param [transitionDuration] - Modal's transition (animation) duration.
     */
    swapModal(newModal, transitionDuration) {
        swapModal(this.modalConnector, newModal, this.payload, transitionDuration);
    }
};
__decorate([
    Inject(ModalType)
], AbstractModal.prototype, "modalConnector", void 0);
__decorate([
    Prop({ type: Boolean, required: true })
], AbstractModal.prototype, "closable", void 0);
__decorate([
    Prop({ type: Boolean, required: true })
], AbstractModal.prototype, "open", void 0);
__decorate([
    Prop({ type: Object, required: false })
], AbstractModal.prototype, "payload", void 0);
AbstractModal = __decorate([
    Component
], AbstractModal);
export { AbstractModal };

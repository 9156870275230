// Copyright © 2021 Move Closer
import { MODAL_TRANSITION_DURATION } from '@config/modal';
/**
 * Swaps the modals - closes the currently-opened one and opens the new one.
 *
 * @param modalConnector - `ModalConnector` service.
 * @param newModal - The modal that is to be opened.
 * @param payload
 * @param [transitionDuration=MODAL_TRANSITION_DURATION] - Modal's transition (animation) duration.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const swapModal = (modalConnector, newModal, payload, transitionDuration = MODAL_TRANSITION_DURATION) => {
    modalConnector.close();
    setTimeout(() => {
        modalConnector.open(newModal, payload);
    }, transitionDuration);
};

import { __decorate } from "tslib";
import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import { Socials } from '../Socials';
/**
 * @emits logIn - When the User clicks the "log in" button.
 * @emits register - When the User clicks the "create new account" button.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
let WelcomeForm = class WelcomeForm extends Vue {
    /**
     * Handles the `@click` event on the "log in" button.
     */
    onLogInBtnClick() {
        return undefined;
    }
    /**
     * Handles the `@submit` event on the `<form>` element.
     */
    onSubmit() {
        return this._model;
    }
    handleOnSocialSelect(social) {
        this.$emit('withSocial', social);
    }
};
__decorate([
    PropSync('model', { type: Object, required: true })
], WelcomeForm.prototype, "_model", void 0);
__decorate([
    Prop({ type: Object, required: false, default: () => { return {}; } })
], WelcomeForm.prototype, "errors", void 0);
__decorate([
    Prop({ type: String, required: false, default: 'btn-primary' })
], WelcomeForm.prototype, "registerButtonClassName", void 0);
__decorate([
    Emit('logIn')
], WelcomeForm.prototype, "onLogInBtnClick", null);
__decorate([
    Emit('register')
], WelcomeForm.prototype, "onSubmit", null);
WelcomeForm = __decorate([
    Component({ name: 'WelcomeForm', components: { Socials } })
], WelcomeForm);
export { WelcomeForm };
export default WelcomeForm;

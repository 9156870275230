var WelcomeModal_1;
import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { AuthServiceType, EventbusType, LocalStorage } from '@movecloser/front-core';
import { AbstractModal } from '@component/Modal';
import { EModal } from '@contract/modal';
import { Inject } from '@plugin/inversify';
import { UserComServiceType } from '@service/usercom';
import { Screen } from '@component/Screen';
import { AuthRepositoryType } from '../../../contracts/repositories';
import { RegisterIntention } from '../../../intentions';
import { GrantType } from '../../../contracts';
import { WelcomeForm } from '../WelcomeForm';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
let WelcomeModal = WelcomeModal_1 = class WelcomeModal extends AbstractModal {
    constructor() {
        super(...arguments);
        /**
         * Determines whether the component is currently performing any async actions.
         */
        this.isActing = false;
        this.errors = {};
        /**
         * Object that collects User input.
         */
        this.formData = {
            agreement: false,
            newsletter: false,
            email: '',
            password: '',
            passwordConfirmation: ''
        };
    }
    /**
     * Handles the `@logIn` event on the `<WelcomeForm>`.
     */
    onLogIn() {
        this.swapModal(EModal.LogIn);
    }
    /**
     * Handles the `@register` event on the `<WelcomeForm>`.
     */
    onRegister() {
        this.registerUser();
    }
    /**
     * Creates new account for the User.
     */
    async registerUser() {
        this.isActing = true;
        const { agreement, email, newsletter, password, passwordConfirmation } = this.formData;
        this.authRepository.register(new RegisterIntention({
            email,
            password,
            passwordConfirmation,
            extra: {
                agreement,
                newsletter
            },
            grantType: GrantType.Credentials
        }))
            .then((model) => {
            this.eventBus.emit('auth:sign-up', model.getUser());
            this.authService.setUser(model.getUser());
            this.authService.setToken(model.toAuthToken());
            this.$store.dispatch('profile/setMe', model.getUser());
            if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                LocalStorage.set('d24-user-id', model.getUser().uniqueId);
                LocalStorage.set('d24-user-com-id', model.getUser().userComId);
                LocalStorage.set('d24-client-id', model.getUser().id);
                LocalStorage.set('d24-client-sub', model.getUser().subscriptionType);
            }
            this.eventBus.emit('auth:logged-in', model.getUser());
            if (newsletter) {
                this.userComService.subscribe({ email }).then(() => {
                    this.eventBus.emit('newsletter-subscribe', {
                        ...{ email },
                        userId: model.getUser().id
                    });
                });
            }
            if (this.hasPayload && typeof this.payload.onSuccess === 'function') {
                this.payload.onSuccess();
            }
            else {
                this.swapModal(EModal.ActivationLinkSent);
            }
        })
            .catch(error => {
            this.errors = {
                ...error.payload
            };
        })
            .finally(() => {
            this.isActing = false;
        });
    }
    async handleRegisterWithSocial(social) {
        this.isActing = true;
        this.authRepository.logInWithSocial(social)
            .then((redirectUrl) => {
            localStorage.setItem('oauthProcess', 'register');
            const originUrl = this.$route.name === 'profile.oauth' ? `${WelcomeModal_1.getDomain()}/` : window.location.href;
            localStorage.setItem('originUrl', originUrl);
            localStorage.setItem('oauthProvider', social);
            window.location.href = redirectUrl;
            this.close();
        })
            .catch(error => {
            this.errors = {
                ...error.payload
            };
        })
            .finally(() => {
            this.isActing = false;
        });
    }
    static getDomain() {
        const currentURL = window.location.href;
        const urlObject = new URL(currentURL);
        const protocol = urlObject.protocol;
        const domain = urlObject.hostname;
        const port = urlObject.port;
        // Combine protocol and domain to form the full domain with https
        const fullDomain = port ? `${protocol}//${domain}:${port}` : `${protocol}//${domain}`;
        return fullDomain;
    }
};
__decorate([
    Inject(AuthRepositoryType)
], WelcomeModal.prototype, "authRepository", void 0);
__decorate([
    Inject(AuthServiceType)
], WelcomeModal.prototype, "authService", void 0);
__decorate([
    Inject(UserComServiceType)
], WelcomeModal.prototype, "userComService", void 0);
__decorate([
    Inject(EventbusType)
], WelcomeModal.prototype, "eventBus", void 0);
WelcomeModal = WelcomeModal_1 = __decorate([
    Component({
        name: 'WelcomeModal',
        components: {
            Screen,
            WelcomeForm
        }
    })
], WelcomeModal);
export { WelcomeModal };
export default WelcomeModal;
